import {
    ADD_LAWYER,
    LOAD_LAWYER,
    LOAD_COWORKER_INFOS,
    UPDATE_COWORKER_INFOS
} from "@/store/mutations-types";
import axiosRequest from "@/AuthenticatedRoute";
import { getObjectKey, isDefined } from "@/utils";

const namespaced = true;

const state = {
    lawyers: [],
    lawyer: null,
    lawyersLoaded: false,
    workerDetails: null
}

const mutations = {
    [ADD_LAWYER](state, {lawyers}){
        for(const itemlawyer of lawyers){
            if(state.lawyers.filter(item => item.id === itemlawyer.id).length === 0){
                state.lawyers.push(itemlawyer)
            }
        }

        state.lawyersLoaded = true
    },

    [LOAD_LAWYER](state, {lawyer}){
        state.lawyer = lawyer
    },

    [LOAD_COWORKER_INFOS](state, {lawyer}){
        state.workerDetails = lawyer
    },

    [UPDATE_COWORKER_INFOS](state, data) {
        state.workerDetails.avatar = data.avatar
        // state.workerDetails.address = data.address
        // state.workerDetails.additional_address = data.additionalAddress
        // state.workerDetails.city = data.city
        // state.workerDetails.country = data.country
        state.workerDetails.office = data.office
        state.workerDetails.civility = data.civility
        state.workerDetails.email = data.email
        state.workerDetails.firstname = data.firstname
        state.workerDetails.fonction = data.fonction
        state.workerDetails.lastname = data.lastname
        state.workerDetails.targetHours = data.targetHours
        state.workerDetails.hourlyWage = data.hourlyWage
        state.workerDetails.is_lawyer = data.isLawyer
        state.workerDetails.mobile_phone = data.mobilePhone
        state.workerDetails.office_phone = data.officePhone
        state.workerDetails.postcode = data.postcode
        state.workerDetails.speciality = data.speciality
        state.workerDetails.billableHours = data.billableHours
    }
}

const actions = {
    //Lawyers
    async loadLawyers({commit}){
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/lawyer/list/external'
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    commit(ADD_LAWYER, {lawyers: buildLawyers(data)})
                    resolve(buildLawyers(data))
                }else{
                    resolve(response)
                }
            }).catch(error => {
                reject(error)
            })
        })
    },

    async loadLawyer({commit}, {id}) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/lawyer/load/' + id
            }).then(response => {
                const data = response.data.data
                if (isDefined(data)) {
                    commit(LOAD_LAWYER, {lawyer: buildLawyer(data)})
                }
                resolve(response)   
            }).catch(error => {
                reject(error)
            })
        })
    },

    async loadCoworker({commit}, {id}) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/lawyer/coworker/' + id
            }).then(response => {
                const data = response.data.data
                if (isDefined(data)) {
                    commit(LOAD_COWORKER_INFOS, {lawyer: buildLawyer(data)})
                }
                resolve(response)   
            }).catch(error => {
                reject(error)
            })
        })
    },

    async updateCoworkerInfos({ commit }, {id, data}) {
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'PUT',
                url: '/user/update/' + id,
                data: data,
            })
                .then(response => {
                    const data = response.data.data;
                    if (isDefined(data)) {
                        commit(UPDATE_COWORKER_INFOS, data)
                    }
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    async loadInternalLawyers({commit}){
        return new Promise((resolve, reject) => {
            axiosRequest({
                method: 'GET',
                url: '/lawyer/list/internal'
            }).then(response => {
                const data = response.data.data

                if (isDefined(data)) {
                    commit(ADD_LAWYER, {lawyers: buildLawyers(data)})
                    resolve(buildLawyers(data))
                }else{
                    resolve(response)
                }
            }).catch(error => {
                reject(error)
            })
        })
    },
}

export default {
    namespaced,
    state,
    actions,
    mutations,
}

function buildLawyers(data){
    let result = []

    for(const item of data){
        result.push(buildLawyer(item))
    }

    return result
}

export function buildLawyer(item, returnArray = false){
    let result = null

    if(isDefined(item)){

        const obj = isDefined(item.contact) ? item.contact:item
        result = {
            'avatar': getObjectKey(obj, 'avatar'),
            // 'additional_address': getObjectKey(obj, 'additional_address'),
            // 'address': getObjectKey(obj, 'address'),
            // 'city': getObjectKey(obj, 'city'),
            'civility': getObjectKey(obj, 'civility'),
            // 'country': getObjectKey(obj, 'country'),
            'email': getObjectKey(obj, 'email'),
            'toque': getObjectKey(obj, 'toque'),
            'firstname': getObjectKey(obj, 'firstname'),
            'fonction': getObjectKey(obj, 'fonction'),
            'id': parseInt(getObjectKey(obj, 'id')),
            'is_client': getObjectKey(item, 'is_client'),
            'is_lawyer': getObjectKey(item, 'is_lawyer'),
            'is_partner': getObjectKey(item, 'is_partner'),
            'is_prospect': getObjectKey(item, 'is_prospect'),
            'is_supplier': getObjectKey(item, 'is_supplier'),
            'lastname': getObjectKey(obj, 'lastname'),
            'lawyer': getObjectKey(obj, 'lawyer'),
            'mobile_phone': getObjectKey(obj, 'mobile_phone', true),
            'name': getObjectKey(obj, 'firstname') + ' ' + getObjectKey(obj, 'lastname'),
            'office_phone': getObjectKey(obj, 'office_phone', true),
            'origin': getObjectKey(obj, 'origin'),
            // 'postcode': getObjectKey(obj, 'postcode'),
            'quality': getObjectKey(obj, 'quality'),
            'targetHours': getObjectKey(obj, 'targetHours'),
            'billableHours': getObjectKey(obj, 'billableHours'),
            'hourlyWage': getObjectKey(obj, 'hourlyWage'),
            'responsible': getObjectKey(obj, 'responsible'),
            "languages": getObjectKey(obj, 'languages'),
            "speciality": getObjectKey(obj, 'speciality'),
            "biography": getObjectKey(obj, 'biography'),
            "distinctions": getObjectKey(obj, 'distinctions'),
            "credentials": getObjectKey(obj, 'credentials'),
            "publications": getObjectKey(obj, 'publications'),
            "academicExperiences": getObjectKey(obj, 'academicExperiences'),
            "professionalExperiences": getObjectKey(obj, 'professionalExperiences'),
            "dblAuth": getObjectKey(obj, 'dblAuth'),
        }

        if(isDefined(obj.office) && obj.office !== null){
            result["office"] = {
              id: getObjectKey(obj.office, "id"),
              name: getObjectKey(obj.office, "name"),
              address: getObjectKey(obj.office, "address"),
              additionalAddress: getObjectKey(obj.office, "additionalAddress"),
              city: getObjectKey(obj.office, "city"),
              postcode: getObjectKey(obj.office, "postcode"),
            };
          }else{
            result["office"] = null;
          }

        if(isDefined(obj.company) && obj.company !== null){
            result['company']= {
                'id': getObjectKey(obj.company, 'id'),
                'name': getObjectKey(obj.company, 'name'),
                'avatar': getObjectKey(obj.company, 'avatar'),
            }
        }else{
            result['company'] = null
        }

        if(isDefined(obj.social_networks)){
            result['social_networks'] = []
            for(const social_network of obj.social_networks) {
                result['social_networks'].push(
                    {
                        'id': getObjectKey(social_network, 'id'),
                        'name': getObjectKey(social_network, 'name'),
                        'link': getObjectKey(social_network, 'link'),
                    }
                )
            }
        }else{
            result['social_networks'] = []
        }

        if(isDefined(obj.interests)){
            result['interests'] = []
            for(const interest of obj.interests) {
                result['interests'].push(
                    {
                        'id': getObjectKey(interest, 'id'),
                        'label': getObjectKey(interest, 'label'),
                    }
                )
            }
        }else{
            result['interests'] = null
        }

        if(returnArray){
            result = [result]
        }
    }

    return result
}
