export const
    account_module_name = 'account',
    ged_module_name = 'ged',
    messenger_module_name = 'messenger',
    project_module_name = 'project',
    crm_module_name = 'crm',
    news_module_name = 'news',
    todo_module_name = 'todo',
    billing_module_name = 'billing',
    office_module_name = 'office',
    lawyer_module_name = 'lawyer',
    facture_module_name='facture',
    disbursement_module_name='disbursement',
    monday_module_name = 'monday',

// GLOBAL
    LOAD_MEGA_SEARCH = 'LOAD_MEGA_SEARCH',
    LOAD_GLOBAL_DATA = 'LOAD_GLOBAL_DATA',
    TOGGLE_LOAD = 'TOGGLE_LOAD',
    TOGGLE_SPINNER = 'TOGGLE_SPINNER',
    RESET_STATE = 'RESET_STATE',

// MONDAYS 
    SET_CURRENT_MONDAY_TASK = monday_module_name + '/SET_CURRENT_MONDAY_TASK',
    SET_USER_MONDAY_TASKS = monday_module_name + '/SET_USER_MONDAY_TASKS',
    SET_MONDAY_TASKS_LOADING = monday_module_name + '/SET_MONDAY_TASKS_LOADING',
    SET_TEAM_MONDAY_TASKS = monday_module_name + '/SET_TEAM_MONDAY_TASKS',
    SET_DAILY_MONDAY_TASKS = monday_module_name + '/SET_DAILY_MONDAY_TASKS',
    SET_PROJECT_MONDAY_TASKS = monday_module_name + '/SET_PROJECT_MONDAY_TASKS',
    RESET_NEW_MONDAY_TASK = monday_module_name + '/RESET_NEW_MONDAY_TASK',
    UPDATE_NEW_MONDAY_TASK = monday_module_name + '/UPDATE_NEW_MONDAY_TASK',
    SHOW_MONDAY_DETAILS = monday_module_name + '/SHOW_MONDAY_DETAILS',
    SET_TASK_COMMENTS = monday_module_name + '/SET_TASK_COMMENTS',
    TOOGLE_LOADING_COMMENTS = monday_module_name + '/TOOGLE_LOADING_COMMENTS',
    SET_TASK_SUBCOMMENTS = monday_module_name + '/SET_TASK_SUBCOMMENTS',
    TOOGLE_LOADING_SUBCOMMENTS = monday_module_name + '/TOOGLE_LOADING_SUBCOMMENTS',
    

//ACCOUNT
    //account
    CREATE_ACCOUNT = account_module_name+'/CREATE_ACCOUNT',
    AUTHENTIFICATION_IN_PROGRESS = 'AUTHENTIFICATION_IN_PROGRESS',
    SET_PARTNER = 'SET_PARTNER',
    SET_CLIENT = 'SET_CLIENT',
    SET_CONNECTED = 'SET_CONNECTED',
    SET_DISCONNECTED = 'SET_DISCONNECTED',
    SET_SUBSCRIPTION_PLAN = 'SET_SUBSCRIPTION_PLAN',
    LOGIN = account_module_name+'/LOGIN',
    ADD_USER = account_module_name+'/ADD_USER',
    ADD_COWORKERS = account_module_name+'/ADD_COWORKERS',
    SET_TEAM = account_module_name+'/SET_TEAM',
    SET_TEAM_MEMBERS = account_module_name+'/SET_TEAM_MEMBERS',
    ADD_TEAM = account_module_name+'/ADD_TEAM',
    ADD_TEAM_MEMBER = account_module_name+'/ADD_TEAM_MEMBER',
    UPDATE_TEAM = account_module_name+'/UPDATE_TEAM',
    REMOVE_TEAM = account_module_name+'/REMOVE_TEAM',
    UPDATE_CURRENT_USER = account_module_name+'/UPDATE_CURRENT_USER',
    GENERATE_QR_CODE = account_module_name+'/GENERATE_QR_CODE',
    LIST_LP_CLIENTS = account_module_name+'/LIST_LP_CLIENTS',
    GET_COMPANY = account_module_name+'/GET_COMPANY',
    GET_SELECTED_LPCLIENT = account_module_name+'/GET_SELECTED_LPCLIENT',
    LIST_CONTACTS = account_module_name+'/LIST_CONTACTS',
    SET_SYNC_USER_ID = account_module_name+'/SET_SYNC_UESR_ID',

    ADD_GLOBAL_NOTIFICATION = account_module_name+'/ADD_GLOBAL_NOTIFICATION',
    ADD_NEW_NOTIFICATION = account_module_name+'/ADD_NEW_NOTIFICATION',
    REMOVE_GLOBAL_NOTIFICATION = account_module_name+'/REMOVE_GLOBAL_NOTIFICATION',
    SET_LISTENING_MERCURE_FOR_NEW_NOTIFICATION = account_module_name+'/SET_LISTENING_MERCURE_FOR_NEW_NOTIFICATION',
    DROP_LISTENING_MERCURE_FOR_NEW_NOTIFICATION = account_module_name+'/DROP_LISTENING_MERCURE_FOR_NEW_NOTIFICATION',
    DELETE_TEAM = account_module_name+'/DELETE_TEAM',
    DELETE_TEAM_MEMBER = account_module_name+'/DELETE_TEAM_MEMBER',
    SET_LISTENING_MERCURE = account_module_name + '/LISTENING_MERCURE',
    DROP_LISTENING_MERCURE = account_module_name + 'DROP_LISTENING_MERCURE',

//GED
    //folder
    SET_CONTENT_LOADER = ged_module_name+'/SET_CONTENT_LOADER',
    SET_DIRECTORY_LOADER = ged_module_name+'/SET_DIRECTORY_LOADER',
    UNSELECT_FOLDER = ged_module_name+'/UNSELECT_FOLDER',
    SET_CURRENT_FOLDER = ged_module_name+'/SET_CURRENT_FOLDER',
    RESET_CURRENT_FOLDER = ged_module_name+'/RESET_CURRENT_FOLDER',
    ADD_FOLDER = ged_module_name+'/ADD_FOLDER',
    EDIT_FOLDER = ged_module_name+'/EDIT_FOLDER',
    DELETE_FOLDER = ged_module_name+'/DELETE_FOLDER',
    TOGGLE_DIRECTORY_LOADED = ged_module_name+'/TOGGLE_DIRECTORY_LOADED',
    SWITCH_NOTIFICATION_SENT_STATE = ged_module_name+'/SWITCH_NOTIFICATION_SENT_STATE',
    SET_LISTENING_MERCURE_FOLDER_UPDATES = ged_module_name+'/SET_LISTENING_MERCURE_FOLDER_UPDATES',
    DROP_LISTENING_MERCURE_FOLDER_UPDATES = ged_module_name+'/DROP_LISTENING_MERCURE_FOLDER_UPDATES',
    SET_LISTENING_MERCURE_FILE_UPDATES = ged_module_name+'/SET_LISTENING_MERCURE_FILE_UPDATES',
    DROP_LISTENING_MERCURE_FILE_UPDATES = ged_module_name+'/DROP_LISTENING_MERCURE_FILE_UPDATES',
    SET_LISTENING_MERCURE_FILE_SHARING = ged_module_name+'/SET_LISTENING_MERCURE_FILE_SHARING',
    DROP_LISTENING_MERCURE_FILE_SHARING = ged_module_name+'/DROP_LISTENING_MERCURE_FILE_SHARING',
    SET_LISTENING_MERCURE_TODO_TASK_UPDATES = ged_module_name+'/SET_LISTENING_MERCURE_TODO_TASK_UPDATES',
    DROP_LISTENING_MERCURE_TODO_TASK_UPDATES = ged_module_name+'/DROP_LISTENING_MERCURE_TODO_TASK_UPDATES',

    //file
    ADD_KEYWORDS =  ged_module_name+'/ADD_KEYWORDS',
    ADD_FILES =  ged_module_name+'/ADD_FILES',
    SELECT_FILE =  ged_module_name+'/SELECT_FILE',
    UNSELECT_FILE =  ged_module_name+'/UNSELECT_FILE',
    UPDATE_FILE =  ged_module_name+'/UPDATE_FILE',
    DELETE_FILE =  ged_module_name+'/DELETE_FILE',
    SHARE_FILE =  ged_module_name+'/SHARE_FILE',
    UNSHARE_FILE =  ged_module_name+'/UNSHARE_FILE',
    UNSHARE_FOLDER = ged_module_name + '/UNSHARE_FOLDER',
    LOAD_FILE_STATUS =  ged_module_name+'/LOAD_FILE_STATUS',

    //search
    SET_SEARCHING = ged_module_name+'/SET_SEARCHING',
    RESET_SEARCHING = ged_module_name+'/RESET_SEARCHING',
    ADD_FILES_SEARCH =  ged_module_name+'/ADD_FILES_SEARCH',
    ADD_FOLDERS_SEARCH =  ged_module_name+'/ADD_FOLDERS_SEARCH',
    ADD_KEYWORDS_SEARCH =  ged_module_name+'/ADD_KEYWORDS_SEARCH',
    ADD_KEYWORDS_FILES_SEARCH =  ged_module_name+'/ADD_KEYWORDS_FILES_SEARCH',
    RESET_KEYWORDS_FILES_SEARCH =  ged_module_name+'/RESET_KEYWORDS_FILES_SEARCH',
    SET_SEARCHING_FOLDER =  ged_module_name+'/SET_SEARCHING_FOLDER',
    RESET_SEARCHING_FOLDER =  ged_module_name+'/RESET_SEARCHING_FOLDER',

    //timeline
    LOAD_TIMELINE = ged_module_name+'/LOAD_TIMELINE',
    ADD_ITEM_TIMELINE = ged_module_name+'/ADD_ITEM_TIMELINE',
    LOAD_DOCUMENT_REQUEST_HISTORY = ged_module_name+'/LOAD_DOCUMENT_REQUEST_HISTORY',
    ADD_ITEM_DOCUMENT_REQUEST_HISTORY = ged_module_name+'/ADD_ITEM_DOCUMENT_REQUEST_HISTORY',

    //favorites
    ADD_FAV = ged_module_name+'/ADD_FAV',
    REMOVE_FAV  = ged_module_name+'/REMOVE_FAV',

    TOGGLE_SIDEBAR = ged_module_name+'/TOGGLE_SIDEBAR',

//MESSENGER
    DROP_CURRENT_PROJECT_STORE = messenger_module_name+'/DROP_CURRENT_PROJECT_STORE',

    //project
    SET_CURRENT_PROJECT = messenger_module_name+'/SET_CURRENT_PROJECT',

    //conversation
    DROP_CURRENT_CONVERSATION = messenger_module_name+'/DROP_CURRENT_CONVERSATION',
    SET_CURRENT_CONVERSATION = messenger_module_name+'/SET_CURRENT_CONVERSATION',
    ADD_CONVERSATION = messenger_module_name+'/ADD_CONVERSATION',
    REMOVE_CONVERSATION = messenger_module_name+'/REMOVE_CONVERSATION',
    UPDATE_CONVERSATION = messenger_module_name+'/UPDATE_CONVERSATION',
    //message
    ADD_TAGS =  messenger_module_name+'/ADD_TAGS',
    ADD_MESSAGE = messenger_module_name+'/ADD_MESSAGE',
    INSERT_MESSAGE = messenger_module_name+'/INSERT_MESSAGE',
    EDIT_MESSAGE = messenger_module_name+'/EDIT_MESSAGE',
    UPDATE_SLUG_MESSAGE = messenger_module_name+'/UPDATE_SLUG_MESSAGE',
    READ_MESSAGE = messenger_module_name+'/READ_MESSAGE',
    SET_META_LIST_MESSAGES = messenger_module_name+'/SET_META_LIST_MESSAGES',
    DELETE_MESSAGE = messenger_module_name+'/DELETE_MESSAGE',
    SET_MESSAGE_TO_BE_EDITED = messenger_module_name+'/SET_MESSAGE_TO_BE_EDITED',
    RESET_MESSAGE_TO_BE_EDITED = messenger_module_name+'/RESET_MESSAGE_TO_BE_EDITED',
    SET_UNREAD_MESSAGES = messenger_module_name+'/SET_UNREAD_MESSAGES',
    TOGGLE_MESSAGES_LOADER = messenger_module_name+'/TOGGLE_MESSAGES_LOADER',
    SET_MESSAGE_TO_BE_RESPONDED=messenger_module_name+'/SET_MESSAGE_TO_BE_RESPONDED',
    RESET_MESSAGE_TO_BE_RESPONDED=messenger_module_name+'/RESET_MESSAGE_TO_BE_RESPONDED',
    SET_LISTENING_MERCURE_MSG=messenger_module_name+'/SET_LISTENING_MERCURE_MSG',
    DROP_LISTENING_MERCURE_MSG=messenger_module_name+'/DROP_LISTENING_MERCURE_MSG',
    SET_LISTENING_MERCURE_CONVERSATION=messenger_module_name+'/SET_LISTENING_MERCURE_CONVERSATION',
    DROP_LISTENING_MERCURE_CONVERSATION=messenger_module_name+'/DROP_LISTENING_MERCURE_CONVERSATION',

    //favoris
    SET_FAV_BOOL = messenger_module_name+'/SET_FAV_BOOL',
    ADD_FAV_CONVERSATION = messenger_module_name+'/ADD_FAV_CONVERSATION',
    EDIT_FAV_CONVERSATION = messenger_module_name+'/EDIT_FAV_CONVERSATION',
    REMOVE_FAV_CONVERSATION  = messenger_module_name+'/REMOVE_FAV_CONVERSATION',

    //search
    SET_SEARCHING_CONVERSATION = messenger_module_name+'/SET_SEARCHING_CONVERSATION',
    RESET_SEARCHING_CONVERSATION = messenger_module_name+'/RESET_SEARCHING_CONVERSATION',
    RESET_SEARCHING_ARRAY = messenger_module_name+'/RESET_SEARCHING_ARRAY',
    ADD_CONVERSATION_SEARCH =  messenger_module_name+'/ADD_FILES_SEARCH_CONVERSATION',
    ADD_MESSAGES_TEXT_SEARCH = messenger_module_name+'/ADD_MESSAGES_TEXT_SEARCH',
    ADD_MESSAGES_FILE_SEARCH = messenger_module_name+'/ADD_MESSAGES_FILE_SEARCH',
    ADD_MESSAGES_TAG_SEARCH = messenger_module_name+'/ADD_MESSAGES_TAG_SEARCH',

    SET_HEIGHT_INPUT_CONTAINER = messenger_module_name+'/SET_HEIGHT_INPUT_CONTAINER',

//PROJECT
    SET_CONTENT_LOADER_PROJECT = project_module_name+'/SET_CONTENT_LOADER',
    SET_DIRECTORY_LOADER_PROJECT = project_module_name+'/SET_DIRECTORY_LOADER',
    ADD_PROJECT = project_module_name+'/ADD_PROJECT',
    UPDATE_PROJECT = project_module_name+'/UPDATE_PROJECT',
    ADD_TEAM_PROJECT = project_module_name+'/ADD_TEAM_PROJECT',
    UPDATE_TEAM_PROJECT = project_module_name+'/UPDATE_TEAM_PROJECT',
    RESET_TEAM_PROJECT = project_module_name+'/RESET_TEAM_PROJECT',
    ADD_EXTERNAL = project_module_name+'/ADD_EXTERNAL',
    DELETE_PROJECT = project_module_name+'/DELETE_PROJECT',
    DELETE_MEMBER_TO_TEAM = project_module_name+'/DELETE_MEMBER_TO_TEAM',
    SET_CURRENT_PROJECT_TEAM_SLUG = project_module_name+'/SET_CURRENT_PROJECT_TEAM_SLUG',
    RESET_CURRENT_PROJECT_TEAM_SLUG = project_module_name+'/RESET_CURRENT_PROJECT_TEAM_SLUG',
    SET_CURRENT_MEMBER_TEAM_SLUG = project_module_name+'/SET_CURRENT_MEMBER_TEAM_SLUG',
    RESET_CURRENT_MEMBER_TEAM_SLUG = project_module_name+'/RESET_CURRENT_MEMBER_TEAM_SLUG',
    READ_PROJECT = project_module_name+'/READ_PROJECT',
    REMOVE_PROJECT = project_module_name+'/REMOVE_PROJECT',
    SET_LISTENING_MERCURE_FILE_UPDATES_SAFE = project_module_name+'/SET_LISTENING_MERCURE_FILE_UPDATES_SAFE',
    SET_LISTENING_MERCURE_FOLDER_UPDATES_SAFE = project_module_name+'/SET_LISTENING_MERCURE_FOLDER_UPDATES_SAFE',
    DROP_LISTENING_MERCURE_FOLDER_UPDATES_SAFE = project_module_name+'/DROP_LISTENING_MERCURE_FOLDER_UPDATES_SAFE',
    DROP_LISTENING_MERCURE_FILE_UPDATES_SAFE = project_module_name+'/DROP_LISTENING_MERCURE_FILE_UPDATES_SAFE',

    // //FACTURE
    // SET_ALL_INVOICEABLE_SENDINGS_OBJECT=facture_module_name+'/SET_ALL_INVOICEABLE_SENDINGS_OBJECT',
    // ADD_INVOICE_SENDING=facture_module_name+'/ADD_INVOICE_SENDING',
    // DELETE_INVOICE_SENDING=facture_module_name+'/DELETE_INVOICE_SENDING',
    // UPDATE_INVOICE_SENDING=facture_module_name+'/UPDATE_INVOICE_SENDING',

    // SET_INVOICEABLE_PROJECTS_OBJECT=facture_module_name+'/SET_INVOICABLE_PROJECT_OBJECT',
    // UPDATE_INVOICEABLE_PROJECT_OBJECT=facture_module_name+'/UPDATE_INVOICEABLE_PROJECT_OBJECT',

    // SET_INVOICEABLE_TEAMS_OBJECT=facture_module_name+'/SET_INVOICABLE_TEAMS_OBJECT',
    // UPDATE_INVOICEABLE_TEAMS_OBJECT=facture_module_name+'/UPDATE_INVOICEABLE_TEAMS_OBJECT',

    // SET_INVOICEABLE_PROJET_MEMBERS_OBJECT=facture_module_name+'/SET_INVOICEABLE_PROJET_MEMBERS_OBJECT',
    // UPDATE_INVOICEABLE_PROJET_MEMBERS=facture_module_name+'/INVOICEABLE_PROJET_MEMBERS',

    //Status
    ADD_PROJECT_STATUS = project_module_name+'/ADD_PROJECT_STATUS',

    //Type
    ADD_PROJECT_TYPE = project_module_name+'/ADD_PROJECT_TYPE',

    //Speciality
    ADD_SPECIALITY = project_module_name+'/ADD_SPECIALITY',

    //Ged
    ADD_FOLDER_PROJECT = project_module_name+'/ADD_FOLDER_PROJECT',
    SELECT_FOLDER_PROJECT = project_module_name+'/SELECT_FOLDER_PROJECT',
    UNSELECT_FOLDER_PROJECT = project_module_name+'/UNSELECT_FOLDER_PROJECT',
    SET_CURRENT_FOLDER_PROJECT = project_module_name+'/SET_CURRENT_FOLDER_PROJECT',
    RESET_CURRENT_FOLDER_PROJECT = project_module_name+'/RESET_CURRENT_FOLDER_PROJECT',
    ADD_FILES_SEARCH_PROJECT = project_module_name+'/ADD_FILES_SEARCH_PROJECT',
    RESET_KEYWORDS_FILES_SEARCH_PROJECT = project_module_name+'/RESET_KEYWORDS_FILES_SEARCH_PROJECT',
    RESET_SEARCHING_FOLDER_PROJECT = project_module_name+'/RESET_SEARCHING_FOLDER_PROJECT',
    SET_SEARCHING_FOLDER_PROJECT = project_module_name+'/SET_SEARCHING_FOLDER_PROJECT',
    RESET_SEARCHING_PROJECT = project_module_name+'/RESET_SEARCHING_PROJECT',
    DELETE_FOLDER_PROJECT = project_module_name+'/DELETE_FOLDER_PROJECT',
    RESET_FOLDER_PROJECT = project_module_name+'/RESET_FOLDER_PROJECT',
    EDIT_FOLDER_PROJECT = project_module_name+'/EDIT_FOLDER_PROJECT',
    ADD_FOLDERS_SEARCH_PROJECT = project_module_name+'/ADD_FOLDERS_SEARCH_PROJECT',

    ADD_FILES_PROJECT = project_module_name+'/ADD_FILES_PROJECT',
    DELETE_FILE_PROJECT = project_module_name+'/DELETE_FILE_PROJECT',
    UPDATE_FILE_PROJECT = project_module_name+'/UPDATE_FILE_PROJECT',
    SELECT_FILE_PROJECT = project_module_name+'/SELECT_FILE_PROJECT',
    UNSELECT_FILE_PROJECT = project_module_name+'/UNSELECT_FILE_PROJECT',
    SHARE_FILE_PROJECT = project_module_name+'/SHARE_FILE_PROJECT',
    UNSHARE_FILE_PROJECT = project_module_name+'/UNSHARE_FILE_PROJECT',

    SET_SEARCHING_PROJECT = project_module_name+'/SET_SEARCHING_PROJECT',

//CRM
    ADD_COMPANY = crm_module_name+'/ADD_COMPANY',
    UPDATE_COMPANY = crm_module_name+'/UPDATE_COMPANY',
    DELETE_COMPANY = crm_module_name+'/DELETE_COMPANY',
    READ_COMPANY = crm_module_name+'/READ_COMPANY',
    REMOVE_COMPANY = crm_module_name+'/REMOVE_COMPANY',
    TOGGLE_COMPANIES_LOADER = crm_module_name+'/TOGGLE_COMPANY_LOADER',
    SET_COMPANIES_FULLY_LOADED = crm_module_name+'/SET_COMPANIES_FULLY_LOADED',

    READ_CONTACT = crm_module_name+'/READ_CONTACT',
    REMOVE_CONTACT = crm_module_name+'/REMOVE_CONTACT',
    ADD_CONTACT = crm_module_name+'/ADD_CONTACT',
    UPDATE_SELECTED_CONTACTS = crm_module_name+'/UPDATE_SELECTED_CONTACTS',
    REMOVE_SELECTED_CONTACT = crm_module_name+'/REMOVE_SELECTED_CONTACT',
    RESET_SELECTED_CONTACTS = crm_module_name+'/RESET_SELECTED_CONTACTS',
    UPDATE_CONTACT = crm_module_name+'/UPDATE_CONTACT',
    DELETE_CONTACT = crm_module_name+'/DELETE_CONTACT',
    DROP_CONTACTS_STORE = crm_module_name+'/DROP_CONTACTS_STORE',
    TOGGLE_CONTACTS_LOADER = crm_module_name+'/TOGGLE_CONTACTS_LOADER',
    SET_CONTACTS_FULLY_LOADED = crm_module_name+'/SET_CONTACTS_FULLY_LOADED',


    SET_MARKETING_CAMPAING_CONTACT=crm_module_name+'/SET_MARKETING_CAMPAING_CONTACT',
    ADD_MARKETING_CAMPAIGN_CONTACT= crm_module_name+'/ADD_MARKETING_COMPAIGN_CONTACT',
    ADD_MARKETING_CAMPAIGN = crm_module_name+'/ADD_MARKETING_COMPAIGN',
    UPDATE_CAMPAIGN = crm_module_name+'/UPDATE_CAMPAIGN',
    UPDATE_MARKETING_CAMPAIGN = crm_module_name+'/UPDATE_MARKETING_CAMPAIGN',
    DELETE_MARKETING_CAMPAIGN = crm_module_name+'/DELETE_MARKETING_CAMPAIGN',
    READ_MARKETING = crm_module_name+'/READ_MARKETING',
    REMOVE_MARKETING = crm_module_name+'/REMOVE_MARKETING',
    TOGGLE_MARKETING_LOADER = crm_module_name+'/TOGGLE_MARKETING_LOADER',
    SET_MARKETING_FULLY_LOADED = crm_module_name+'/SET_MARKETING_FULLY_LOADED',

    ADD_DISCIPLINE = crm_module_name+'/ADD_DISCIPLINE',

    ADD_INTEREST = crm_module_name+'/ADD_INTEREST',

    ADD_EVENT = crm_module_name+'/ADD_EVENT',
    DELETE_EVENT = crm_module_name+'/DELETE_EVENT',

//NEWS
    ADD_NEWS = news_module_name+'/ADD_NEWS',
    ADD_NEWS_SCRAP = news_module_name+'/ADD_NEWS_SCRAP',
    ADD_NEWS_COMPANIES = news_module_name+'/ADD_NEWS_COMPANIES',
    ADD_NEWS_KEYWORD = news_module_name+'/ADD_NEWS_KEYWORD',
    READ_NEWS = news_module_name+'/READ_NEWS',
    REMOVE_NEWS = news_module_name+'/REMOVE_NEWS',
    RESET_NEWS_KEYWORD = news_module_name+'/RESET_NEWS_KEYWORD',
    DELETE_NEWS = news_module_name+'/DELETE_NEWS',
    UPDATE_NEWS = news_module_name+'/UPDATE_NEWS',
    ADD_KEYWORDS_NEWS = news_module_name+'/ADD_KEYWORDS_NEWS',

//TODOS
    RESET_TODO = todo_module_name+'/RESET_TODO',
    ADD_TODO = todo_module_name+'/ADD_TODO',
    DELETE_TODO = todo_module_name+'/DELETE_TODO',
    UPDATE_TODO = todo_module_name+'/UPDATE_TODO',
    SET_CATEGORY_TODO = todo_module_name+'/SET_CATEGORY_TODO',

//BILLING
    ADD_BILLING = billing_module_name+'/ADD_BILLING',
    SET_ACTIVE_TEAM_BILLINGS = billing_module_name+'/SET_ACTIVE_TEAM_BILLINGS',
    SET_CURRENT_PROJECT_INVOICING_SLUG = billing_module_name+'/SET_CURRENT_PROJECT_INVOICING_SLUG',
    ADD_TEAM_BILLING = billing_module_name+'/ADD_TEAM_BILLING',
    ADD_ISSUED_BILLING = billing_module_name+'/ADD_ISSUED_BILLING',
    UPDATE_ISSUED_BILLING=billing_module_name+'UPDATE_ISSUED_BILLING',
    ADD_ARCHIVED_BILLING = billing_module_name+'/ADD_ARCHIVED_BILLING',
    TOGGLE_MODAL_REMINDER = billing_module_name+'/TOGGLE_MODAL_REMINDER',
    DELETE_ISSUED_BILLING=billing_module_name+'/DELETE_ISSUED_BILLING',
    DELETE_TEAM_BILLING=billing_module_name+'/DELETE_TEAM_BILLING',
    SET_CHOSEN_BILLING_OBJ=billing_module_name+'/SET_CHOSEN_BILLING_OBJ',
    UPDATE_PROVISION=billing_module_name+'/UPDATE_PROVISION',
    SET_PDF_PREVIEWER_TYPE=billing_module_name+'/SET_PDF_PREVIEWER_TYPE',

//OFFICES
    ADD_OFFICE = office_module_name+'/ADD_OFFICE',

//LAWYERS
    READ_LAWYER = lawyer_module_name+'/READ_LAWYER',
    ADD_LAWYER = lawyer_module_name+'/ADD_LAWYER',
    UPDATE_LAWYER = lawyer_module_name+'/UPDATE_LAWYER',
    LOAD_LAWYER = lawyer_module_name+'/LOAD_LAWYER',
    LOAD_COWORKER_INFOS = lawyer_module_name+'/LOAD_COWORKER_INFOS',
    UPDATE_COWORKER_INFOS = lawyer_module_name+ '/UPDATE_COWORKER_INFOS',

//DISBURSMENT
    ADD_DISBURSEMENT=disbursement_module_name+'/ADD_DISBURSEMENT',
    READ_DISBURSEMENT=disbursement_module_name+'/READ_DISBURSEMENT',
    UPDATE_DISBURSEMENT=disbursement_module_name+'/UPDATE_DISBURSEMENT',
    DELETE_DISBURSEMENT=disbursement_module_name+'/DELETE_DISBURSEMENT',
    UPDATE_TEAM_BILLING=disbursement_module_name+'/UPDATE_TEAM_BILLING',
    SET_WIDGET_INFO=disbursement_module_name+'/SET_WIDGET_INFO',
    RESET_TEAM_BILLING=disbursement_module_name+'/RESET_TEAM_BILLING',
    SET_NULL_BILLING=disbursement_module_name+'/SET_NULL_BILLING',
    UPDATE_TEAM_BILLINGS=disbursement_module_name+'/UPDATE_TEAM_BILLINGS'
