<template>
  <div id='app' class='component-item dashboard'>

    <div v-if='isLogin'>
      <UserLogin />
    </div>

    <div v-else-if='isAuthentificationInProgress'>
      <TwoFactor />
    </div>

    <div v-else-if='isLPClientPage'>
      <PartnerLPClients />
    </div>
    <div v-else-if='ResetPasswordVerifToken'>
      <ResetPasswordVerifToken />
    </div>

    <div v-else-if='isResetPasswordTokenValid'>
      <ResetPasswordTokenValid />
    </div>

    <div v-else-if="isOnlyOfficePage" style="width: 100%; height:100%">
      <GEDFileEditor />
    </div>


    <div v-else-if='this.isConnectedStatus && !this.isLPClientPage' class='page-wrapper' id="scroll-container"
      @scroll="onScroll">
      <Sidebar :collapsed='sidebarCollapsed' @toggle-collapsed='toggleSidebarCollapsed'
        v-if="!$route.meta.hideSideBar" />

      <div :class="[sidebarCollapsed === true ? collapsed : '', contentWrapper,]"
        :style="[$route.meta.hideSideBar && { paddingLeft: 0 }]">
        <div :class="{ 'main-content': !isOnlyOfficePage, 'h-full': isOnlyOfficePage }">

          <Topbar :collapsed='sidebarCollapsed' @toggle-collapsed='toggleSidebarCollapsed'
            v-if="!$route.meta.hideTopBar" />

          <AdminTopbar v-if="$route.meta.showAdminTobar" />
          <div :class="{ 'main-content-router': !isOnlyOfficePage, 'h-full': isOnlyOfficePage }">
            <!-- Your fixed-top navigation bar -->
            <router-view :key='$route.fullPath' />
          </div>
          <!-- Display the details of a task as an overlay (check the "Mondays" section on the dashboard or in the "Dossier client" section - "Gantt Display view") -->
          <div
            :class="(showMondayOverlay ? 'visible' : 'invisible') + ' fadeoverlay-div d-flex fixed-top justify-content-end w-100 h-100'">
            <div @click="hideMondayOverlay" class="col-0 col-sm-7 side-overlay-content"></div>
            <div class="h-100 col-12 col-sm-5 float-right p-4 shadow bg-white rounded mb-5">
              <MondayForeground />
            </div>
          </div>
          <div
            :class="(showGEDLoader ? 'visible' : 'invisible') + ' fadeoverlay-div d-flex fixed-top justify-content-end w-100 h-100'">
            <div @click="hideMondayOverlay" class="col-12 loader-content-displayer">
              Téléversement en cours...
              <font-awesome-icon icon="spinner" class="spinner-animation"></font-awesome-icon>
            </div>
          </div>
          <div v-if="chronos.length > 0"
            :class="(chronos.length > 0 ? 'visible' : 'invisible') + ' pending-chronos hover-chrono'">
            <div class="text-white fs-4 text-capitalize">
              {{ chronos[0].task?.title }}
            </div>
            <div class="mx-4 opacity-75">
              <font-awesome-icon icon="stop" size="lg" @click="reset(chronos[0])"
                style="color: red; margin-right: 10px;" />
              <font-awesome-icon icon="play" size="lg" @click="start(chronos[0])" style="color: white;"
                v-if="!chronos[0].isRunning" />
              <font-awesome-icon icon="pause" size="lg" @click="pause(chronos[0])" style="color: white;" v-else />
            </div>
            <div class="text-white fs-3" @click.stop="toogleDisplayChronos()">
              {{ formatTime(chronos[0].value) }}
            </div>
            <div @click.stop="toogleDisplayChronos()">
              <span v-if="chronos.length === 1">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="redirect-icon">
                  <path
                    d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" />
                </svg></span>
              <font-awesome-icon icon="chevron-up" style="color: white;" class="chevron-icon" v-else-if="showChronos" />
              <font-awesome-icon icon="chevron-down" style="color: white;" class="chevron-icon" v-else />
            </div>
            <div class="badge" v-if="chronos.length > 1">{{ chronos.length }}</div>
          </div>

          <div :class="(showChronos ? 'visible' : 'invisible') + ' chronos-list'">
            <div class="chronos-item hover-chrono" v-for="(chr, index) of chronos" :key="index">
              <div class="fs-4 text-capitalize chronos-title">
                {{ chr.task?.title }}
              </div>
              <div class="mx-4 opacity-75">
                <font-awesome-icon icon="stop" size="lg" @click="reset(chr)" style="color: red; margin-right: 10px;" />
                <font-awesome-icon icon="play" size="lg" @click.stop="start(chr)" v-if="!chr.isRunning" />
                <font-awesome-icon icon="pause" size="lg" @click.stop="pause(chr)" v-else />
              </div>
              <div class="fs-3">
                {{ formatTime(chr.value) }}
              </div>
              <span @click.stop="showTask(chr.task.id)">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="chronos-item-icon">
                  <path
                    d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" />
                </svg></span>
            </div>
          </div>

          <div @click.stop="redirectToProject()"
            :class="(time !== '00:00:00' && displayOverlayTimer ? 'visible' : 'invisible') + ' custom-chrono hover-chrono'">
            <div class="text-white fs-4 text-capitalize">
              {{ projectTimer?.name?.toLowerCase() }}
            </div>
            <div class="mx-4 opacity-75">
              <font-awesome-icon icon="play" size="lg" style="color: white;" v-if="this.time === '00:00:00'" />
              <font-awesome-icon icon="pause" size="lg" style="color: white;" v-else />
            </div>
            <div class="text-white fs-3">
              {{ time }}
            </div>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="redirect-icon" color="white">
                <path
                  d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" />
              </svg></span>
          </div>
        </div>
        <Footer class='footerDashboard' v-if='isDashboard' />
      </div>

      <b-button v-if="showFlyingBtn && !isStarterPlan" @click="handleQuickAccessMessenger" class='flyingWidgetMessenger'
        variant='outline-secondary'>
        <svg style="cursor: pointer;" viewBox="0 0 650 512" height="30" width="30" fill="#ffff"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M88.2 309.1c9.8-18.3 6.8-40.8-7.5-55.8C59.4 230.9 48 204 48 176c0-63.5 63.8-128 160-128s160 64.5 160 128s-63.8 128-160 128c-13.1 0-25.8-1.3-37.8-3.6c-10.4-2-21.2-.6-30.7 4.2c-4.1 2.1-8.3 4.1-12.6 6c-16 7.2-32.9 13.5-49.9 18c2.8-4.6 5.4-9.1 7.9-13.6c1.1-1.9 2.2-3.9 3.2-5.9zM0 176c0 41.8 17.2 80.1 45.9 110.3c-.9 1.7-1.9 3.5-2.8 5.1c-10.3 18.4-22.3 36.5-36.6 52.1c-6.6 7-8.3 17.2-4.6 25.9C5.8 378.3 14.4 384 24 384c43 0 86.5-13.3 122.7-29.7c4.8-2.2 9.6-4.5 14.2-6.8c15.1 3 30.9 4.5 47.1 4.5c114.9 0 208-78.8 208-176S322.9 0 208 0S0 78.8 0 176zM432 480c16.2 0 31.9-1.6 47.1-4.5c4.6 2.3 9.4 4.6 14.2 6.8C529.5 498.7 573 512 616 512c9.6 0 18.2-5.7 22-14.5c3.8-8.8 2-19-4.6-25.9c-14.2-15.6-26.2-33.7-36.6-52.1c-.9-1.7-1.9-3.4-2.8-5.1C622.8 384.1 640 345.8 640 304c0-94.4-87.9-171.5-198.2-175.8c4.1 15.2 6.2 31.2 6.2 47.8l0 .6c87.2 6.7 144 67.5 144 127.4c0 28-11.4 54.9-32.7 77.2c-14.3 15-17.3 37.6-7.5 55.8c1.1 2 2.2 4 3.2 5.9c2.5 4.5 5.2 9 7.9 13.6c-17-4.5-33.9-10.7-49.9-18c-4.3-1.9-8.5-3.9-12.6-6c-9.5-4.8-20.3-6.2-30.7-4.2c-12.1 2.4-24.7 3.6-37.8 3.6c-61.7 0-110-26.5-136.8-62.3c-16 5.4-32.8 9.4-50 11.8C279 439.8 350 480 432 480z" />
        </svg>
      </b-button>

      <b-modal id='modalMessenger' size='xl' hide-header hide-footer>
        <Messenger name='Messagerie'
          :class="{ 'quick-access': quickAccessMessenger, 'sidebar-access': !quickAccessMessenger }" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import { formatTime, isDefined } from './utils';
import { createSpinner } from '@syncfusion/ej2-popups';
import Vue from 'vue';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faPlay, faPause, faSpinner, faChevronDown, faChevronUp, faStop } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { MondayType } from './enum';

Vue.component('font-awesome-icon', FontAwesomeIcon);
library.add(faPlay, faPause, faSpinner, faChevronDown, faChevronUp, faStop);


export default {
  name: 'App',

  components: {
    Sidebar: () => import(/* webpackChunkName: 'Sidebar' */ './components/layout/Sidebar.vue'),
    AdminTopbar: () => import(/* webpackChunkName: 'AdminTopbar' */ './components/layout/AdminTopbar.vue'),
    Topbar: () => import(/* webpackChunkName: 'Topbar' */ './components/layout/Topbar.vue'),
    Footer: () => import(/* webpackChunkName: 'Footer' */ './components/layout/Footer.vue'),
    UserLogin: () => import(/* webpackChunkName: 'UserLogIn' */ './components/Account/UserLogIn.vue'),
    TwoFactor: () => import(/* webpackChunkName: 'TwoFactor' */ './components/Account/TwoFactor.vue'),
    PartnerLPClients: () => import(/* webpackChunkName: 'LPClients' */ './components/Partner/Dashboard/LPClients.vue'),
    Messenger: () => import(/* webpackChunkName: 'Messenger' */ './components/Messenger/Messenger.vue'),
    ResetPasswordVerifToken: () => import(/* webpackChunkName: "ResetPasswordVerifToken" */ './components/Account/ResetPasswordVerifToken.vue'),
    ResetPasswordTokenValid: () => import(/* webpackChunkName: "ResetPasswordTokenValid" */ './components/Account/ResetPasswordTokenValid.vue'),
    MondayForeground: () => import(/* webpackChunkName: "MondayForeground" */ './components/Mondays/MondayForeground.vue'),
    GEDFileEditor: () => import(/* webpackChunkName: "GEDFileEditor" */ './components/GED/GEDFileEditor.vue'),
    //ChatBotInterface:()=> import(/* webpackChunkName: "ChatInterface" */ './components/BotInterface/ChatInterface.vue')
    // StopWatch: () => import(/* webpackChunkName: "StopWatch" */ './components/Project/StopWatch.vue')
  },

  data() {
    return {
      sidebarCollapsed: true,
      contentWrapper: 'content-wrapper',
      collapsed: 'collapsed',
      quickAccessMessenger: false,
      showChronos: false,
    }
  },

  mounted() {
    createSpinner({
      target: document.getElementById('app')
    });
    if (this.$store.state.isConnected) {
      this.listenMercure()
    }

    document.addEventListener('click', () => {
      this.closeAllMondayDropDownTabs()
      this.showChronos = false;
    })
  },
  created() {
    // événement visant à déclencher les désabonnements Mercure lors de la sortie de l'app
    window.addEventListener('beforeunload', this.beforeWindowUnload)
  },
  beforeDestroy() {
    this.stopListenMercure()
    // suppression event prévu pour la désabo Mercure
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
    document.removeEventListener('click', this.closeAllMondayDropDownTabs())
  },
  computed: {
    ...mapGetters([
      'isStarterPlan'
    ]),
    ...mapState('stopWatchModule', {
      currentTime: 'currentTime',
      projectTimer: 'project',
      displayOverlayTimer: 'displayOverlayTimer'
    }),

    ...mapState({
      isConnected: 'isConnected',
      dataIsLoaded: 'dataIsLoaded',
      authenticationInProgress: 'authenticationInProgress',
    }),
    ...mapState('project', {
      project: 'project'
    }),
    ...mapState('account', ['userInfos']),
    ...mapState('ged', {
      showGEDLoader: 'showGEDLoader'
    }),
    ...mapState('uploadSys', ['numFiles']),
    ...mapState('monday', ['showMondayOverlay', 'chronos', 'selectedMenu', 'dailyHasNextPage', 'isLoadingDailyTask']),

    isDashboard() {
      return this.$route.name === 'Dashboard' || this.$route.name === 'Dashboard Partner'
    },
    showFlyingBtn() {
      return this.$route.name !== 'Messagerie' && !this.$route.meta.hideSideBar
    },

    time: {
      get() {
        return this.currentTime
      },
      set(value) {
        this.setCurrentTime(value)
      }
    },

    isConnectedStatus() {
      let bool = false
      if (isDefined(this.isConnected)) {
        bool = this.isConnected
      }
      return bool
    },

    isLPClientPage() {
      return this.$route.name === 'LPClients'
    },

    ResetPasswordVerifToken() {
      return this.$route.name === 'ResetPasswordVerifToken'
    },

    isResetPasswordTokenValid() {
      return this.$route.name === 'ResetPasswordTokenValid'
    },

    isAuthentificationInProgress() {
      return this.$route.name === 'TwoFactor'
    },

    hasNextValues() {
      return this.dailyHasNextPage && !this.isLoadingDailyTask
    },

    isLogin() {
      return this.$route.name === 'Login'
    },

    isOnlyOfficePage() {
      return this.$route.name === 'FileEditor'
    }
  },

  methods: {
    ...mapMutations('stopWatchModule', {
      setCurrentTime: 'setCurrentTime',
      setDisplayOverlayTimer: 'setDisplayOverlayTimer'
    }),

    ...mapMutations('monday', ['updateSpecificDailyTask']),
    ...mapActions(["listenMercure", "stopListenMercure"]),

    ...mapMutations('monday', {
      closeAllMondayDropdownTabs: 'closeAllDropdowns',
      setMondayType: 'setMondayType',
    }),

    ...mapActions('monday', ['hideMondayDetails', "setChrono", "saveChrono", "removeChrono", 'loadTodoTaskGroup']),

    formatTime,

    closeAllMondayDropDownTabs() {
      this.closeAllMondayDropdownTabs()
    },

    beforeWindowUnload() {
      this.stopListenMercure()
    },

    toggleSidebarCollapsed() {
      this.sidebarCollapsed = !this.sidebarCollapsed
    },
    handleQuickAccessMessenger() {
      this.quickAccessMessenger = true;
      this.$bvModal.show('modalMessenger');
    },
    handleSidebarMessenger() {
      this.quickAccessMessenger = false;
      this.$bvModal.show('modalMessenger');
    },
    hideMondayOverlay() {
      this.hideMondayDetails()
    },
    redirectToProject() {
      if (this.project) {
        this.setDisplayOverlayTimer(false)
        this.$router.push({
          name: 'Project View',
          params: { id: this.project.slug, title: this.project.name }
        }).then(() => window.scrollTo(0, 0));
      }
    },

    onScroll() {
      if (this.selectedMenu?.nature === 'daily') {
        const container = document.getElementById("scroll-container")
        if (container.scrollTop + container.clientHeight >= container.scrollHeight - 10) {
          if (this.hasNextValues) {
            this.setMondayType(MondayType.DAILY_TASK);
            this.loadTodoTaskGroup({ id: this.userInfos.id })
          }
        }
      }
    },
    toogleDisplayChronos() {
      if (this.chronos.length > 1) {
        this.showChronos = !this.showChronos
        return;
      }

      this.showTask(this.chronos[0].task.id)
    },

    showTask(id) {
      this.showChronos = false
      const onPage = this.$route.name === 'Dashboard'
      if (!onPage) {
        this.$router.push({
          name: 'Dashboard'
        }).then(() => this.scrollToTask(id));
      } else {
        this.scrollToTask(id)
      }
    },

    scrollToTask(id) {
      const target = document.getElementById('task ' + id);
      if (target) {
        target.scrollIntoView({ behavior: 'smooth' });
      }
    },

    start(chrono) {
      chrono.isRunning = true;
      chrono.intervalId = setInterval(() => {
        chrono.value++;
      }, 1000);
      this.saveChronoState(chrono)
    },
    pause(chrono) {
      chrono.isRunning = false;
      clearInterval(chrono.intervalId);
      this.saveChronoState(chrono);
    },

    reset(chrono) {
      this.saveChronoState(chrono, true);
      chrono.isRunning = false;
      clearInterval(chrono.intervalId);
      this.removeChrono(chrono)
    },

    async saveChronoState(chrono, fetchData = false) {
      this.setChrono(chrono)

      if (fetchData) {
        await this.saveChrono({ chrono: chrono, isNew: false }).then((response) => {
          if (this.selectedMenu?.nature === 'daily') {
            this.updateSpecificDailyTask(response)
          } else if (this.$route.path.includes('/project')) {
            this.loadTodoTaskGroup({ id: this.project.slug });
          } else {
            this.loadTodoTaskGroup({ id: this.userInfos.id });
          }
        }).catch((err) => {
          console.log("error while save chrono", err)
        })

        return
      }

      this.saveChrono({ chrono: chrono, isNew: false })
    }
  },
}
</script>

<style scoped lang="scss">
/**
 * IMPORT CSS SYNCFUSION
 * @type {AxiosInstance}
 */
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-vue-grids/styles/material.css';
/**
 * IMPORT CSS SYNCFUSION
 * @type {AxiosInstance}
 */

.e-spinner-pane {
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2000;
}

.main-content-router {
  z-index: 998;
}

.overlay-div {
  z-index: 999;
}

.side-overlay-content {
  background-color: rgba(0, 0, 0, 0.7);
}

.loader-content-displayer {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.custom-chrono {
  width: fit-content;
  position: fixed;
  top: 0;
  right: 190px;
  margin-top: 25px;
  border-radius: 50px;
  padding: 12px 35px;
  z-index: 999;
  color: white;
  background-color: #5233ff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}


.redirect-icon {
  width: 12px;
  margin-left: 6px;
  margin-top: -5px;
  color: white;
  fill: white;
  white-space: normal;
}

.h-full {
  height: 100%;
}

.pending-chronos {
  position: fixed;
  top: 0;
  right: 190px;
  width: fit-content;
  border-radius: 50px !important;
  z-index: 998;
  color: white;
  background-color: #5233ff;
  padding: 10px 15px;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.badge {
  position: absolute;
  top: -7px;
  right: 0;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: green;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chevron-icon {
  margin: 4px 0 0 6px;
}

.chronos-list {
  position: fixed;
  top: 0;
  right: 190px;
  width: fit-content;
  z-index: 1000;
  margin-top: 75px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: end;
  row-gap: 10px;
  height: fit-content;
  background-color: white;
  box-shadow: 0 5px 3px rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.06);
  padding: 25px;
}

.chronos-title {
  width: 200px;
  text-overflow: hidden;
  overflow: hidden;
  text-wrap: nowrap;
  display: flex;
  justify-content: start;
}

.chronos-item {
  background-color: white;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.chronos-item-icon {
  width: 12px;
  margin-left: 6px;
  margin-top: -5px;
  fill: #4e4f51;
  color: #4e4f51;
  white-space: normal;
}

.hover-chrono:hover {
  background-color: #4028c6;
  color: white;

  .chronos-item-icon {
    fill: white;
    color: white;
  }
}
</style>