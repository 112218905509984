import Vue from "vue";
import Vuex from "vuex";
import account from "./modules/account";
import monday from "./modules/monday";
import ged from "./modules/ged";
import messenger from "./modules/messenger";
import crm from "./modules/crm";
import project from "./modules/project";
import news from "./modules/news";
import todos from "./modules/todos";
import office from "./modules/office";
import socials from "./modules/socials";
import billing from "./modules/billing";
import lawyer from "./modules/lawyer";
import stopWatchModule from "./modules/stopWatch";
import improvisedTask from "./modules/improvisedTask";
import uploadSys from "./modules/uploadSys";
import profile from "./modules/profile";

import {
  CREATE_ACCOUNT,
  LOAD_GLOBAL_DATA,
  LOAD_MEGA_SEARCH,
  AUTHENTIFICATION_IN_PROGRESS,
  SET_CONNECTED,
  SET_DISCONNECTED,
  SET_PARTNER,
  SET_SUBSCRIPTION_PLAN,
  TOGGLE_LOAD,
  TOGGLE_SPINNER,
  SET_CLIENT,
  SET_LISTENING_MERCURE,
  DROP_LISTENING_MERCURE,
  ADD_NEW_NOTIFICATION,
  REMOVE_FAV_CONVERSATION,
  ADD_FAV_CONVERSATION,
  REMOVE_CONVERSATION,
  DELETE_FILE,
  UNSHARE_FILE,
  DELETE_FOLDER,
  RESET_CURRENT_FOLDER,
  UNSHARE_FOLDER,
} from "./mutations-types";

import VuexPersistence from "vuex-persist";
import { isDefined } from "@/utils";
// import { axiosRequest } from "../main";
import axiosRequest from "../AuthenticatedRoute";
import eventModule from "./modules/events.js";
import userModule from "./modules/tempRegistr.js";
import validTimeModule from "./modules/validTime";
import disbursement from "./modules/disbursement";
import { SubscriptionPlan } from "../enum.js";
import {
  buildGlobalNotification,
  getObjectKey,
  stringIsNotBlank,
} from "../utils.js";

const vuexLocal = new VuexPersistence({
  // storage: window.sessionStorage,
  storage: window.localStorage,
  reducer: function (val) {
    if (val.account.userInfos.token === null) {
      return {};
    }

    return val;
  },
});

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    profile,
    account,
    crm,
    ged,
    messenger,
    project,
    news,
    todos,
    billing,
    office,
    lawyer,
    eventModule,
    userModule,
    stopWatchModule,
    improvisedTask,
    validTimeModule,
    disbursement,
    uploadSys,
    monday,
    socials,
  },

  state: {
    loadStatus: "",
    activeModule: "",
    isConnected: false,
    megaSearchData: [],
    dataIsLoaded: false,
    authenticationInProgress: false,
    isPartner: false,
    isClient: false,
    searchInputToolBar: "",
    subscriptionPlan: null,
    load: false,
    spinner: false,
    listenMercure: {},
  },

  mutations: {
    [AUTHENTIFICATION_IN_PROGRESS](state) {
      state.authenticationInProgress = true;
    },

    [SET_CONNECTED](state) {
      state.isConnected = true;
    },

    [SET_DISCONNECTED](state) {
      state.isPartner = false;
      state.isClient = false;
      state.authenticationInProgress = false;
      state.isConnected = false;
      state.dataIsLoaded = false;
    },

    [SET_PARTNER](state) {
      state.isPartner = true;
    },

    [SET_CLIENT](state) {
      state.isClient = true;
    },

    [SET_SUBSCRIPTION_PLAN](state, plan) {
      state.subscriptionPlan = plan;
    },

    [CREATE_ACCOUNT](state) {
      state.loadStatus = "loading";
    },

    [LOAD_MEGA_SEARCH](state, data) {
      state.megaSearchData = [];
      state.megaSearchData = data;
    },

    [LOAD_GLOBAL_DATA](state, data) {
      data;
      state.dataIsLoaded = true;
    },

    [TOGGLE_LOAD](state) {
      state.load = !state.load;
    },

    [TOGGLE_SPINNER](state) {
      state.spinner = !state.spinner;
    },

    [SET_LISTENING_MERCURE](state, eventSource) {
      state.listenMercure = eventSource;
    },
    [DROP_LISTENING_MERCURE](state) {
      state.listenMercure = {};
    },
  },
  getters: {
    isStarterPlan(state) {
      return state.subscriptionPlan === SubscriptionPlan.STARTER_AVOCAT;
    },
    isStarterCorporatePlan(state) {
      return state.subscriptionPlan === SubscriptionPlan.STARTER_CORPORATE;
    },
    isEntrepriseCorporatePlan(state) {
      return state.subscriptionPlan === SubscriptionPlan.ENTREPRISE_CORPORATE;
    },
    isEntrepriseAvocatPlan(state) {
      return state.subscriptionPlan === SubscriptionPlan.ENTREPRISE_AVOCAT;
    },
  },
  actions: {
    async listenMercure({ state, commit, dispatch }) {
      if (!(state.listenMercure instanceof EventSource)) {
        const userEmail = state.account.userInfos.email;

        if (stringIsNotBlank(userEmail)) {
          const eventURL = process.env.VUE_APP_MERCURE_SERVER_URL + userEmail,
            eventSource = new EventSource(eventURL, {
              headers: {
                Authorization: "Bearer " + state.account.userInfos.mercureToken,
              },
            });

          eventSource.onmessage = async (event) => {
            // Will be called every time an update is published by the server
            const data = JSON.parse(event.data);
            const _event = getObjectKey(data, "event", true);
            const slug = getObjectKey(data, "data", true);
            switch (_event) {
              case "create_conversation":
                await dispatch("messenger/loadConversationBySlug", { slug });
                break;

              case "update_conversation":
                break;

              case "delete_conversation":
                commit("messenger/" + REMOVE_CONVERSATION, { slug });
                break;

              case "add_fav_conversation":
                commit("messenger/" + ADD_FAV_CONVERSATION, { slug });
                break;

              case "remove_fav_conversation":
                commit("messenger/" + REMOVE_FAV_CONVERSATION, { slug });
                break;

              case "create_message":
                await dispatch("messenger/loadMessageBySlug", { slug });
                await dispatch("messenger/getUnreadMessages");
                break;

              case "update_message":
                break;

              case "project_notifications":
                commit(
                  "account/" + ADD_NEW_NOTIFICATION,
                  buildGlobalNotification(slug)
                );
                break;

              case "sharing_files":
                await dispatch("ged/mercureShareFiles", slug.file);
                break;

              case "unshare_files":
                commit("ged/" + UNSHARE_FILE, {
                  slugFile: slug.slug,
                  emailUser: slug.email,
                  isOwner: slug.isOwner,
                });
                break;

              case "delete_file":
                commit("ged/" + DELETE_FILE, { slug });
                break;

              case "update_file":
                break;

              case "sharing_folders":
                dispatch("ged/mercureShareFolders", slug);
                break;

              case "unshare_folders":
                commit("ged/" + UNSHARE_FOLDER, {
                  slug: slug,
                });
                break;

              case "delete_folder":
                commit("ged/" + DELETE_FOLDER, { slug });
                commit("ged/" + RESET_CURRENT_FOLDER);
                break;

              case "update_folder":
                break;
              default:
                console.log("Event not yet managed");
                break;
            }
          };

          eventSource.onerror = (err) => {
            console.error("Notification listener lost connection...", err);
          };
          commit(SET_LISTENING_MERCURE, eventSource);
        }
      }
    },

    async stopListenMercure({ state, commit }) {
      if (state.listenMercure instanceof EventSource) {
        await state.listenMercure.close();
        commit(DROP_LISTENING_MERCURE);
      }
    },

    disconnect({ commit, dispatch }) {
      commit(SET_DISCONNECTED);
      dispatch("account/resetState");
    },

    megaSearch({ commit }, { query }) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: "GET",
          url: "/mega-search/search?q=" + query,
        })
          .then((response) => {
            const data = response.data.data;

            if (isDefined(data)) {
              commit(LOAD_MEGA_SEARCH, data);
            }

            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // eslint-disable-next-line
    loadLanguage({}, id) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: "GET",
          url: "user/language/" + id,
        })
          .then((response) => {
            // eslint-disable-next-line no-undef
            if (Weglot) {
              // eslint-disable-next-line no-undef
              const currentLang = Weglot.getCurrentLang();
              const lpClientLang = response.data.code;

              if (currentLang !== lpClientLang) {
                // eslint-disable-next-line no-undef
                Weglot.switchTo(lpClientLang);
              }
            }

            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    loadLanguages() {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: "GET",
          url: "user/language/list",
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    toggleDataLoaded({ commit }) {
      commit(LOAD_GLOBAL_DATA);
    },

    toggleLoad({ commit }) {
      commit(TOGGLE_LOAD);
    },

    toggleSpinner({ commit }) {
      commit(TOGGLE_SPINNER);
    },
  },

  plugins: [vuexLocal.plugin],
});

// Écoutez les modifications de l'état de stockage local
// pour synchroniser les onglets ouverts
// window.addEventListener('storage', (event) => {
//   if (event.key === vuexLocal.key) {
//     const newState = JSON.parse(event.newValue);
//     if (newState) {
//       store.replaceState(newState);
//     }
//   }
// });

// export default store
